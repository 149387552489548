<template>
    <v-col
        v-if="isOnlyView"
        class="py-5"
        cols="12"
    >
        <label>{{ title }}</label>
        <div
            class="ql-editor"
            v-html="bodyValue"
        />
    </v-col>

    <v-col
        v-else
        cols="12"
    >
        <div
            class=" d-flex justify-space-between"
            style="align-items: center; flex-wrap: wrap; font-size: 16px;"
        >
            <span>{{ title }}</span>
            <!--                                <span>{{ $t(`admin.posts.${editorVisual ? 'editorVisual' : 'editorCode'}`) }}</span>-->
            <v-switch
                v-model="editorVisual"
                inset
                :label="$t('admin.posts.editorVisual')"
            />

            <v-btn
                class="mx-2 pb-0 mb-0 mt-1"
                fab
                dark
                color="primary"
                small
                @click="restoreBody"
            >
                <v-icon dark>
                    mdi-undo
                </v-icon>
            </v-btn>
        </div>

        <vue-editor
            v-if="editorVisual"
            v-model="_bodyValue"
            :editor-options="editorSettings"
        />
        <v-textarea
            v-else
            name="input-7-1"
            :value="bodyValue"
            outlined
            @change="editorCodeChanged($event)"
        />
    </v-col>
</template>

<script>
import { VueEditor, Quill } from "vue2-editor";
import { ImageDrop } from "quill-image-drop-module";
import ImageResize from "quill-image-resize-module";

Quill.register("modules/imageDrop", ImageDrop);
Quill.register("modules/imageResize", ImageResize);

export default {
    name: "BodyEditor",
    components: { VueEditor },
    props: {
        isOnlyView: {
            type: Boolean,
            required: true
        },
        bodyValue: {
            type: String,
            required: true
        },
        bodyValueInitial: {
            type: String | null,
            required: true
        },
        title: {
            type: String,
            default: ''
        }
    },
    data: function () {
        return {
            editorSettings: {
                modules: {
                    imageDrop: true,
                    imageResize: {}

                }
            },
            editorVisual: false
        }
    },
    computed: {
        _bodyValue: {
            get() {
                return this.bodyValue
            },
            set(value) {
                this.editorCodeChanged(value);
            }
        }
    },
    mounted() {
        this.addTooltipToEditor();
    },
    methods: {
        editorCodeChanged(element) {
            this.$emit('update:bodyValue', element);
        },
        restoreBody() {
            try {
                this.$emit('update:bodyValue', this.bodyValueInitial);
                // this.editedItem.body.value = this.item.attributes.body
            } catch (e) {

            }
        },
        addTooltipToEditor() {
            const toolbarTooltips = {
                font: 'Select a font',
                size: 'Select a font size',
                header: 'Select the text style',
                bold: 'Bold',
                italic: 'Italic',
                underline: 'Underline',
                strike: 'Strikethrough',
                color: 'Select a text color',
                background: 'Select a background color',
                script: {
                    sub: 'Subscript',
                    super: 'Superscript'
                },
                list: {
                    ordered: 'Numbered list',
                    bullet: 'Bulleted list',
                    check: 'Checked list'
                },
                indent: {
                    '-1': 'Decrease indent',
                    '+1': 'Increase indent'
                },
                direction: {
                    rtl: 'Text direction (right to left | left to right)',
                    ltr: 'Text direction (left ro right | right to left)'
                },
                // align: 'Text alignment',
                align: {
                    default: 'Text alignment left',
                    center: 'Text alignment center',
                    justify: 'Text alignment justify',
                    right: 'Text alignment right'
                },
                blockquote: 'Blockquote',
                'code-block': 'Code block',
                link: 'Insert a link',
                image: 'Insert an image',
                video: 'Insert an video',
                formula: 'Insert a formula',
                clean: 'Clear format',
                'add-table': 'Add a new table',
                'table-row': 'Add a row to the selected table',
                'table-column': 'Add a column to the selected table',
                'remove-table': 'Remove selected table',
                help: 'Show help'
            };

            const lang = this.$store.getters['languages/getLang'];
            if (lang !== 'en') {
                Object.keys(toolbarTooltips).map(key => {
                    let value;
                    if (typeof toolbarTooltips[key] === 'string') {
                        value = 'editorActions.' + key;
                        toolbarTooltips[key] = this.$t(value);
                    } else {
                        Object.keys(toolbarTooltips[key]).map(subKey => {
                            value = 'editorActions.' + key + '.' + subKey;
                            toolbarTooltips[key][subKey] = this.$t(value);
                        });
                    }
                });
            }

            const showTooltip = (which, el) => {
                let tool;
                if (which === 'button') {
                    tool = el.className.replace('ql-', '');
                } else if (which === 'span') {
                    tool = el.className.replace('ql-', '');
                    tool = tool.substr(0, tool.indexOf(' '));
                }
                if (tool) {
                    // if element has value attribute.. handling is different
                    // buttons without value
                    if (el.value === '') {
                        if (toolbarTooltips[tool]) {
                            if (toolbarTooltips[tool].default) {
                                el.setAttribute('title', toolbarTooltips[tool].default);
                            } else { el.setAttribute('title', toolbarTooltips[tool]); }
                        }
                    } else if (typeof el.value !== 'undefined') { // buttons with value
                        if (toolbarTooltips[tool][el.value]) { el.setAttribute('title', toolbarTooltips[tool][el.value]); }
                    } else { el.setAttribute('title', toolbarTooltips[tool]); }// default
                }
            };

            const toolbarElement = document.querySelector('.ql-toolbar');
            if (toolbarElement) {
                const matchesButtons = toolbarElement.querySelectorAll('button');
                for (const el of matchesButtons) {
                    showTooltip('button', el);
                }
                // for submenus inside
                const matchesSpans = toolbarElement.querySelectorAll('.ql-toolbar > span > span');
                for (const el of matchesSpans) {
                    showTooltip('span', el);
                }
            }
        }
    }
}
</script>

<style scoped>

</style>
